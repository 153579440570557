<template>
  <div>
    <b-card>
      <b-row class="text-right">
        <b-col lg="7"> </b-col>
        <b-col lg="4">
          <!-- <h4 class="text-left">วันที่</h4> -->
          <date-picker
            v-model="form.date"
            :masks="{ input: 'MM/YYYY' }"
            :model-config="datepickerConfig"
            mode="date"
            @input="datepickerEvent"
          >
            <template v-slot="{ inputValue, togglePopover }">
              <!-- :mode="date-time" -->
              <b-form-input
                autocomplete="off"
                class="border px-2 rounded"
                :value="inputValue"
                @click="togglePopover()"
              />
            </template>
          </date-picker>
        </b-col>
        <b-col lg="1">
          <b-button v-if="!loading" variant="relief-success" @click="Load()">
            Search
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-right">
        <b-col>
          <b-button
            variant="relief-success"
            disabled
            class="mr-1"
            v-if="loading"
          >
            <b-spinner small /> &nbsp; Loading...
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <table class="table">
      <thead>
        <tr>
          <td>เลขที่ใบเสร็จ</td>
          <td>ชื่อ-สกุล</td>
          <td>ที่อยู่</td>
          <td>Tax ID</td>
          <td>วันที่</td>
          <td>ยอดรับ</td>
          <td>VAT</td>
          <td>หัก ณ ที่จ่าย</td>
          <td>รับ NET</td>
          <td>จ่ายแบบ</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(x, index) in filter_data" :key="index">
          <td>{{ x.tax_invoice_no }}</td>
          <td>{{ x.customer_name }}</td>
          <td>{{ x.billing_address }}</td>
          <td>{{ x.card_id }}</td>
          <td>{{ x.date }}</td>
          <td>{{ x.price_after_vat }}</td>
          <td>{{ x.vat }}</td>
          <td>{{ x.with_holding_price }}</td>
          <td>{{ x.after_with_holding_price }}</td>
          <td>{{ x.payment_method }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import {
  BFormInput,
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
} from "bootstrap-vue";
export default {
  components: {
    DatePicker,
    BFormInput,
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
  },
  data() {
    return {
      datepickerConfig: {
        type: "string",
        mask: "YYYYMM", // Uses 'iso' if missing
      },
      loading: false,
      form: {
        date: this.formatDateToYYYYMM(new Date()),
      },
      filter_data: [],
    };
  },
  created() {
    this.Load();
  },
  methods: {
    datepickerEvent(date) {
      console.log("date", date);
    },
    Load() {
      this.$http({
        method: "POST",
        url: `/tax-invoice/month_summary`,
        data: {
          yearmonth: this.form.date,
        },
      }).then((x) => {
        this.filter_data = x.data.data;
      });
    },
    formatDateToYYYYMM(date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // JavaScript months are 0-indexed.
      return `${year}${month.toString().padStart(2, "0")}`;
    },
  },
};
</script>
